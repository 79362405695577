
// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
import { Grid, Icon, Modal } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import DataForm from "./dataForm";

import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';
import { getData } from "tools/helpers";

import SoftAlert from "components/SoftAlert";
import { insertDataJson, updateItem, deleteItem } from "tools/helpers";
import { generateSlug } from "tools/helpers";

function Customers() {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("USER_APP_SERVICES")));
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [status, setStatus] = useState({status: null, message: ""});
  const [statusForm, setStatusForm] = useState({status: null, message: ""});
  const [errorFields, setErrorsFields] = useState([]);
  const emptyData = {
    slug : '',
    name : '',
  }
  const [formData, setFormData] = useState(emptyData);
  const [isEdit, setIsEdit] = useState(false);

  const setState = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      slug: generateSlug(e.target.value)
    });
  };

  const showSaveForm = () =>{
    setStatusForm({status: null, message: ""});
    setIsEdit(false);
    setFormData(emptyData);
    setOpen(true);
  }

  const showDeleteData = (row) =>{
    setStatusForm({status: null, message: ""});
    setOpenCancel(true);
    setFormData(row);
  }

  const columns = [
    // {
    //     name: 'ID',
    //     selector: row => row.id,
    //     compact: true,
    // },
    {
      name: 'Nombre',
      selector: row => <Link size="h5" to={`/profile/${row.slug}`} >{row.name}</Link> ,
      compact: true,
    },
    {
      name: 'Slug',
      selector: row => row.slug,
      compact: true,
      grow:2
   },
     {
      name: 'Email Verificado',
      selector: row => <Icon fontSize="medium" color={`${row.verified === 'true' ? 'success' : 'disabled'}`}>check_circle</Icon>,
      compact: true
      },
    {
    name: '',
    selector: row => <SoftButton
                    md={6}
                    variant="gradient"
                    color= {`${parseInt(row.status) === 1 ? 'error' : 'dark'}`}
                    size="medium"
                    onClick = {()=>showDeleteData(row)}
                  >
                    {parseInt(row.status) === 1 ? 'Habilitar' : 'Suspender'}
                  </SoftButton>,
    compact: true
    },
  ];

  const getProfiles = async () => {
    const route = `Profile`;
    const send = await getData(route);
    if (send.code === 200) {
      setData(send.data);
    }
  };


const user = JSON.parse(localStorage.getItem("USER_APP_SERVICES"));
  const requiredFields = ['name', 'slug'];
  const sendForm = async() =>{
    const validationErrors = validateForm(formData, requiredFields);
    if(validationErrors.length > 0)
    {
      return;
    }

    setStatusForm({status: true, message: "Guardando..."});
    const route = `Profile`;
    let send = null;

    if(isEdit){
      send = await updateItem(`${route}/${formData.id}`, formData);
    }else{
      send = await insertDataJson(route, formData);
    }

    if (send.code === 201) {
      setStatusForm({
        status: true,
        message: "Se ha guardado el perfil satisfactoriamente",
        label: "success",
      });
      setOpen(false);
      setFormData(emptyData);
      getProfiles();
      return;
    }

    if (send.status === "error") {
      requiredFields.forEach(item=>{
        if (typeof send.errors === 'object'){
          if(item in send.errors){
            console.log(item)
            setStatusForm({
              status: true,
              message: send.errors[item][0],
              label: "error",
            });
            return;
          }
        }
      })
      return;
    }

    setStatusForm({
      status: true,
      message: "Ha ocurrido algo, intente de nuevo",
      label: "error",
    });
  }

  const deleteData = async (row) => {
    setStatus({
      status: true,
      message: "Actualizando...",
      label: "warning",
    });
    const route = `Profile`;
    const newData = {
      name: formData.name,
      status: parseInt(formData.status) === 0 ? 1 : 0
    }
    const send = await updateItem(`${route}/${formData.id}`, newData);
    if (send.code === 201) {
      setStatus({
        status: true,
        message: `Se ha ${parseInt(formData.status) === 1 ? 'habilitado' : 'suspendido'} correctamente`,
        label: "success",
      });
      getProfiles();
      setFormData(emptyData);
      setOpenCancel(false);
      return;
    }

    if (send.status === "401") {
      setStatus({
        status: true,
        message: "No tiene permiso para realizar esta acción",
        label: "error",
      });
      setFormData(emptyData);
      return;
    }

    setStatus({
      status: true,
      message: "Ha ocurrido algo o el elemento se está usando",
      label: "error",
    });
  };

  const validateForm = (obj, requiredFields) =>{
    const errors = [];
    setErrorsFields([]);
    requiredFields.forEach(field => {
      console.log(obj[field])
      if (!obj.hasOwnProperty(field)) {
        errors.push(field);
      } else if (! obj[field] ) {
        errors.push(field);
      }
 
    });
  
    if (errors.length > 0) {
      setErrorsFields(errors);
    }

    return errors;
  }

  useEffect(()=>{
    if(userData.type === 'user'){
      navigate(`/profile/${userData.slug}`);
    }
    getProfiles();
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Card style={{ overflow: 'initial'}}>
        <SoftBox sx={8} md={12}>
            <Grid container p={4}>
              <Grid container item md={12}>
                <Grid item md={6}>
                  <SoftTypography variant="h5">Perfiles </SoftTypography>
                </Grid>
                <Grid item container md={6} justifyContent="flex-end">
                  {
                    user.type === 'admin' || user.admin === 'true' ?
                      <SoftButton
                        md={6}
                        variant="gradient"
                        color= "primary"
                        size="medium"
                        onClick = {()=>showSaveForm()}
                      >
                        Nuevo
                      </SoftButton>
                    : null
                  }
                </Grid>
              </Grid>
              <Grid xs={12}>
              {
                status.status ? (
                  <SoftBox extAlign="center" p={1}>
                    <SoftAlert color={status.label}> 
                      <SoftTypography fontSize={12} p={0} color="white"  fontWeight="medium">
                        {status.message}
                      </SoftTypography>
                    </SoftAlert>
                  </SoftBox>
                ): null
              }
            </Grid>
              <DataTableExtensions
                columns={columns}
                data={data}
                export={false}
                print={false}
                filterPlaceholder="Buscar..."
              >
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  paginationComponentOptions={{
                    rowsPerPageText: 'Registros por página:', 
                    rangeSeparatorText: 'de', 
                    noRowsPerPage: false, 
                    selectAllRowsItem: true, 
                    selectAllRowsItemText: 'Todos',
                  }}
                  paginationPerPage={20}
                  noDataComponent={<SoftBox py={5}>No se han encontrado resultados.</SoftBox>}
                />
              </DataTableExtensions>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
      <Modal
          open={open}
          onClose={()=>setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={style} md={12}>
          <Grid md={12} xs={12} mt={1} spacing={2} justifyContent='center'>
              <DataForm 
              formData = {formData} 
              setState = {setState}
              errorFields = {errorFields} 
              statusForm = {statusForm}
              />
              <Grid container spacing={2} justifyContent='flex-end'>
                <Grid item >
                  <SoftButton
                      md={6}
                      variant="gradient"
                      color= "primary"
                      size="medium"
                      onClick = {()=>sendForm(false)}
                  >
                      Guardar
                </SoftButton>
              </Grid>
              <Grid item >
                <SoftButton
                  md={6}
                  variant="gradient"
                  color= "dark"
                  size="medium"
                  onClick = {()=>setOpen(false)}
                >
                  Cerrar
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
      <Modal
          open={openCancel}
          onClose={()=>setOpenCancel(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <SoftBox sx={styleCancel}>
            <SoftTypography id="modal-modal-title" variant="h6" component="h2">
                ¿Está seguro que desea {parseInt(formData.status) === 1 ? 'habilitar' : 'suspender' } el perfil?: 
            </SoftTypography>
            <SoftTypography id="modal-modal-title" variant="h6" component="h2">
                <b>{formData.tradename}</b>?
            </SoftTypography>
          <Grid container xs={12} mt={1} spacing={2} justifyContent='center'>
            <Grid item >
                <SoftButton
                    md={6}
                    variant="gradient"
                    color= "primary"
                    size="medium"
                    onClick = {()=>deleteData()}
                >
                    Sí, {parseInt(formData.status) === 1 ? 'Habilitar' : 'Suspender' } perfil
              </SoftButton>
            </Grid>
            <Grid item >
              <SoftButton
                md={6}
                variant="gradient"
                color= "dark"
                size="medium"
                onClick = {()=>setOpenCancel(false)}
              >
                Cerrar
              </SoftButton>
            </Grid>
          </Grid>
        </SoftBox>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '70%',
  maxWidth: '900px',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styleCancel = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default Customers;
